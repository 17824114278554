import { listPublicPlans } from '@wix/ambassador-pricing-plans-v2-plan/http';
import { PeriodUnit, PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { ElementRole } from '../../constants/elements';
import { Analytics } from '../../services/analytics';
import { SinglePlanInteractions } from '../../types/SinglePlanFedops';
import { toError } from '../../utils/errors';
import model from './model';

export enum StateId {
  Loaded = 'planInfo',
  Spinner = 'loading',
  Empty = 'empty',
}

const getDemoPlan = (t: EditorScriptFlowAPI['translations']['t']): PublicPlan => ({
  name: t('blocks.demo-plan.name'),
  description: t('blocks.demo-plan.description'),
  pricing: {
    price: {
      value: '15',
      currency: 'USD',
    },
    subscription: {
      cycleCount: 3,
      cycleDuration: {
        count: 3,
        unit: PeriodUnit.MONTH,
      },
    },
  },
  perks: {
    values: [t('blocks.demo-plan.perk'), t('blocks.demo-plan.perk'), t('blocks.demo-plan.perk')],
  },
});

export default model.createController(({ $w, $bind, $widget, flowAPI }) => {
  const { planId } = $widget.props;

  const getDemoPlanIfEditor = () => {
    return flowAPI.environment.isEditor ? getDemoPlan(flowAPI.translations.t) : null;
  };

  const fetchPlan = async (): Promise<PublicPlan | null> => {
    if (!planId) {
      return getDemoPlanIfEditor();
    }
    // TODO: Use warmup data
    const response = await flowAPI.httpClient.request(listPublicPlans({ planIds: [planId] }));
    return response.data.plans?.[0] ?? getDemoPlanIfEditor();
  };

  const setPlan = async () => {
    if (!flowAPI.environment.isEditor && !flowAPI.environment.isSSR) {
      showSpinner();
    }

    try {
      const plan = await fetchPlan();
      if (!plan) {
        showEmptyState();
      } else {
        await $w(`#${ElementRole.PlanWidget}`).setPlan(plan);
        showLoadedState();
        const analytics = new Analytics(flowAPI.controllerConfig.wixCodeApi.window);
        analytics.addProductImpression([plan]);
      }
      flowAPI.fedops.interactionEnded(SinglePlanInteractions.SetPlanData);
    } catch (e) {
      flowAPI.errorMonitor.captureException(toError(e));
      showEmptyState();
    }
  };

  const showSpinner = () => {
    return $w(`#${ElementRole.MultiStateBox}`).changeState(StateId.Spinner);
  };

  const showEmptyState = () => {
    return $w(`#${ElementRole.MultiStateBox}`).changeState(StateId.Empty);
  };

  const showLoadedState = () => {
    return $w(`#${ElementRole.MultiStateBox}`).changeState(StateId.Loaded);
  };

  return {
    pageReady: async () => {
      // Viewer controller can be executed without widget being rendered
      // This happens in editor, when page has an OOI widget, but not blocks widget
      const isWidgetRendered = $w(`#${ElementRole.PlanWidget}`).setPlan !== undefined;
      if (isWidgetRendered) {
        flowAPI.fedops.interactionStarted(SinglePlanInteractions.SetPlanData);
        return setPlan();
      } else {
        flowAPI.errorMonitor.captureMessage('Single Plan Widget does not exist in page', {
          tags: {
            isEditor: flowAPI.environment.isEditor,
          },
        });
      }
    },
    exports: {},
  };
});
